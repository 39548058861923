@font-face {
  font-family: 'MadaniArabic';
  src: url('../static/fonts/MadaniArabic-Bold.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MadaniArabic';
  src: url('../static/fonts/MadaniArabic-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MadaniArabic';
  src: url('../static/fonts/MadaniArabic-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MadaniArabic';
  src: url('../static/fonts/MadaniArabic-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MadaniArabic';
  src: url('../static/fonts/MadaniArabic-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'MadaniArabic';
  src: url('../static/fonts/MadaniArabic-ExtraLight.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
