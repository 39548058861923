#login {
  color: white;
  display: grid;
  min-height: 100vh;
  @supports (height: 100dvh) {
    min-height: 100dvh;
  }
  .foreground {
    padding: 12% 10%;
    position: relative;
    max-width: 900px;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: auto;
    > img {
      min-width: 210px;
      @media screen and (min-width: 769px) {
        width: 320px;
      }
    }
    > h1 {
      font-weight: 500;
      max-width: 300px;
      text-wrap: balance;
      @media screen and (min-width: 769px) {
        max-width: max-content;
        font-size: 3rem;
      }
    }
  }

  a {
    color: white;
    line-height: 1.4;
    padding: 12px 30px 16px;
    border-radius: 100px;
    width: 100%;
    max-width: 320px;
    box-shadow: 0px 0px 12px 0px #00000066;
    background-image: linear-gradient(90deg, #ff401c 0%, #f5740b 100%);
    font-size: 1.25rem;
    font-weight: 500;
  }
}
