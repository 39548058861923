[id='kpi-remove-dialog'] {
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: auto;
  background-color: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 820px;
  margin: 0;
  padding: 2rem 3rem;

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(2px);
  }

  h2 {
    font-weight: 500;
    text-wrap: pretty;
    text-align: center;
    span {
      font-weight: 600;
    }
  }

  > section > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-top: 5rem;

    button {
      padding: 0.5rem 1rem;
      border-radius: 33px;

      &.remove {
        background-color: #ff5243;
        color: black;
        font-weight: 500;
      }
    }
  }
}
