[class='kpi-entry'] {
  display: flex;
  align-items: center;
  position: relative;
  gap: 0.25rem;
  width: max-content;

  > article {
    display: flex;
    gap: 0.25rem;
    position: relative;
    transition: transform 0.5s;
    min-width: 0;
    max-width: 100%;
    flex: 1 1 auto;

    &.drag {
      transition: none;
    }
  }

  > div {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    padding: 0 2rem;
    background-color: var(--background);
    border-radius: 29px 0px 0px 29px;
    border: 1px solid #072b4e;
    color: #fff;
    gap: 1rem;
    opacity: 0;
    pointer-events: none;

    span {
      max-width: 60px;
      text-align: center;
      font-weight: 500;
    }

    img {
      width: 21px;
      height: 29px;
    }

    @media (min-width: 768px) {
      position: static;
      opacity: 1;
      padding: 0;
      height: 224px;
      padding: 0 1.25rem;
      cursor: pointer;
      pointer-events: all;

      span {
        writing-mode: vertical-rl;
        transform: rotate(180deg);
        text-orientation: mixed;
        order: 1;
      }

      img {
        order: 2;
      }
    }
  }

  @media (min-width: 768px) {
    width: 100%;
  }
}
