#menu {
  @media screen and (width <= 768px) {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    font-size: 2.5rem;
    padding-bottom: 2.5rem;
    height: 100%;
    button {
      padding-left: 40px;
    }
  }
  @media screen and (width > 768px) {
    position: absolute;
    right: 1rem;
    top: 1rem;
    border-radius: 18px;
    width: fit-content;
    max-width: 260px;
    padding: 20px;
    font-size: 1.75rem;
    &::before{
      content: "";
      position: absolute;
      inset: 0;
      padding: 1px;
      border-radius: 18px;
      border: 1px solid #4BCCF5 100%;
      pointer-events: none;
      @supports (-webkit-mask-composite: xor){
        border: none;
        background-image: linear-gradient(180deg, #4B57F5 0%, #4BCCF5 100%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask-composite: xor;
        -webkit-mask-composite: xor;
      }
    }
    menu {
      padding: 0;
    }
    button {
      margin-top: 2rem;
    }
  }
  &.closed {
    display: none;
  }

  &.open {
    z-index: 110;
    background-color: var(--background);
    color: white;
    display: flex;
    flex-direction: column;
    > menu {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 3rem;
      font-weight: 600;
    }
    menu > li {
      width: fit-content;
      .custom-select {
        --select-border-color: #7a7a7a;
        font-size: 18px;
        .custom-select-header,
        .custom-select-list {
          padding: 1rem 1.25rem;
        }

        img {
          transform: rotate(0deg);
          transition: all 0.5s ease;
        }

        .custom-select-header {
          > div {
            display: flex;
            gap: 1.25rem;
            > span {
              font-weight: 100;
              > span {
                font-weight: 500;
              }
            }
          }
        }
        &:focus-within {
          img {
            transform: rotate(90deg);
          }
          .custom-select-list {
            z-index: 120;
            background-color: var(--background);
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 1rem;

            > li {
              display: block;
              color: #676767;
              font-weight: 500;
              .selected {
                color: white;
              }
            }
          }
        }
      }
    }
    > button {
      background-color: transparent;
      color: white;
      width: fit-content;
      font-size: 2rem;
      font-weight: 100;
      img {
        margin-left: 12px;
      }
    }
  }
}
