.metric {
  background: linear-gradient(180deg, #393c5d 0%, rgba(57, 59, 91, 0) 100%);
  border-radius: 29px;
  padding: 21px 27px;
  width: 100%;

  & header {
    display: flex;
    width: 100%;
    font-family: 'MadaniArabic';

    > div:not(.icon) {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      label,
      p {
        margin: 0;
      }

      label {
        color: #eaeaea;
        font-size: 1.25rem;
        font-weight: 600;
        text-transform: lowercase;

        &::first-letter {
          text-transform: uppercase;
        }
      }

      p {
        font-size: 1rem;
        text-transform: lowercase;
        font-weight: 400;
        &::first-letter {
          text-transform: capitalize;
        }
      }

      p {
        color: #fff;
      }
    }

    .icon {
      width: 24px;
      height: 24px;
      background-color: white;
      mask-size: contain;
      mask-repeat: no-repeat;
      display: inline-block;

      &.week {
        mask-image: url('/icons/week.svg');
      }

      &.month {
        mask-image: url('/icons/calendar.svg');
      }
    }

    span {
      color: white;
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  > div {
    display: flex;
    margin-top: 1.25rem;

    img {
      aspect-ratio: 1 / 1;
    }

    > div:first-child {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 0.75rem;

      .target-container {
        background-color: white;
        color: #121212;
        align-items: center;
        gap: 0.5rem;
        display: flex;
        padding: 0.25rem 0.75rem 0.25rem 0.5rem;
        border-radius: 3rem;

        span {
          font-size: 1rem;
          font-weight: 500;
        }
      }
    }

    .input-container {
      background-color: #171618;
      padding: 0.75rem 1.25rem;
      max-width: 14rem;
      border-radius: 1rem;
      display: flex;
      align-items: flex-end;
      flex: 1;
      gap: 0.25rem;

      &:focus {
        outline: 1px solid white;
        border: none;
      }

      input {
        width: 100%;
        background-color: transparent;
        font-size: 1.5em;
        margin-bottom: 2px;
        color: white;
        outline: none;
      }

      img {
        margin-bottom: 4px;
        width: 1.75rem;
        height: 1.75rem;
        aspect-ratio: 1 / 1;
      }
    }

    @media (max-width: 400px) {
      .input-container {
        max-width: 9rem;
        input {
          font-size: 1.75rem;
        }
      }
    }
  }

  &.missing {
    background: linear-gradient(180deg, #682213 0%, rgba(104, 34, 19, 0) 100%);

    > div {
      .input-container {
        input {
          color: #cc3212;
        }
      }
    }
  }

  &.show-status {
    background: linear-gradient(180deg, #464c9b 0%, rgba(70, 76, 155, 0) 100%);
    > div > div > .result-img-container {
      border-radius: 100%;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &.happy {
        background-color: #00054d;
      }

      &.sad {
        background-color: #3b3e5c;
      }
    }
  }

  &:not(.show-status) {
    > div > div > .result-img-container {
      display: none;
    }
  }

  &.fallback {
    label,
    p {
      display: block;
      height: 23px;
      border-radius: 18px;
      background-color: #3b3e5c;
    }

    label {
      width: 180px;
    }

    p {
      width: 120px;
      height: 18px;
    }
  }
}
