#charts {
  > .container {
    overflow: hidden;
    > h1 {
      color: #fff;
      font-weight: 600;
      font-size: 3rem;
      margin: 1.75rem 0 2rem 0;
    }
    > section {
      margin-bottom: 2.5rem;
      display: flex;
      padding-right: 10px;
      align-items: center;
      &.filters {
        justify-content: space-between;
        span {
          color: #fcff61;
          font-weight: 400;
        }
        button {
          background-color: #7c81eb;
          border-radius: 100%;
          aspect-ratio: 1/1;
          width: 37px;
          > img {
            width: 16px;
          }
        }
      }
    }
    > div {
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    @media (min-width: 768px) {
      overflow: auto;
    }
  }
}
