[class='kpi-role-user-dialog'] {
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: auto;
  background-color: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 820px;
  margin: 0;
  padding: 2rem 3rem;
  overflow: visible;

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(2px);
  }

  > form > header {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-bottom: 2rem;
    > button {
      background-color: #161617;
      backdrop-filter: blur(16px);
      color: white;
      font-weight: 600;
      padding: 0.5rem 1.25rem;
      border-radius: 33px;
    }

    > div {
      cursor: pointer;
      background-color: #7c81eb;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 37px;
      aspect-ratio: 1 / 1;

      .close {
        width: 16px;
        aspect-ratio: 1 / 1;
        border-radius: 100%;
        padding: 0.5rem;
        background-color: white;
        mask-size: contain;
        mask-repeat: no-repeat;
        display: inline-block;
        mask-image: url('/icons/cross.svg');
      }
    }
  }

  > form > h2 {
    font-weight: 500;
    text-wrap: pretty;
    text-align: center;
    margin-bottom: 3rem;
  }

  > form > section {
    display: flex;
    gap: 1rem;
    align-items: center;

    > p {
      flex-shrink: 0;
    }

    > .select-wrapper {
      width: 100%;
      position: relative;
    }

    .hidden {
      width: 100%;
      height: 1px;
      width: 1px;
      margin: -1px;
      margin-top: -1rem;
      padding: 0;
      border: 0;
      overflow: hidden;
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      white-space: nowrap;
      position: absolute;
      left: 50%;
      bottom: -1px;
      transform: translateX(-50%);
    }
  }

  .custom-select-wrapper {
    height: 50px;
    width: 100%;

    .custom-select {
      .custom-select-header > div {
        margin-left: 12px;
        margin-right: 12px;
        color: #3e3e3e;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .icon-arrow {
          width: 16px;
          aspect-ratio: 1 / 1;
          background-color: black;
          mask-size: contain;
          mask-repeat: no-repeat;
          display: inline-block;
          mask-image: url('/icons/arrow.svg');
          transform: rotate(90deg);
          margin-top: 8px;
          transition: transform 0.3s;
        }
      }

      &:focus-within {
        img {
          transform: rotate(90deg);
        }
        .custom-select-header {
          border-bottom: none;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;

          .icon-arrow {
            margin-top: 0;
            transform: rotate(-90deg);
          }
        }
        .custom-select-list {
          top: 100%;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-top: none;
          z-index: 120;
          background-color: white;
          max-height: 120px;
          color: #3e3e3e;

          &::-webkit-scrollbar-track,
          ::-webkit-scrollbar-track {
            background: transparent;
          }
          &::-webkit-scrollbar-thumb,
          ::-webkit-scrollbar-thumb {
            background: #3e3e3e;
            cursor: pointer;
          }

          > li {
            margin-top: 1rem;
            display: flex;
            justify-content: flex-start;
            margin-left: 12px;
            margin-right: 12px;
            &:first-child {
              margin-top: 1rem;
            }
            &:last-child {
              margin-bottom: 1rem;
            }
            .selected {
              width: 100%;
              color: #7c81eb;
            }
          }
        }
      }
    }
  }

  > form > p {
    color: red;
    text-align: center;
  }
}
