.custom-select {
  height: 100%;
  width: 100%;
  position: relative;
  cursor: pointer;
}

.custom-select .icon-angle_down {
  transform: rotate(0deg);
  transition: all 0.5s ease;
  color: #878787;
}

.custom-select > .custom-select-header {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-select:focus-within .custom-select-list {
  width: 100%;
  display: block;
  position: absolute;
  top: 120%;
  left: 0;
  right: 0;
  overscroll-behavior-y: contain;
  overflow-y: auto;
  z-index: 1000;
}

.custom-select-info,
.custom-select-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.selected-network {
  display: flex;
  align-items: center;
  gap: 5px;
}

.selected-network img {
  width: 22px;
  height: 22px;
}

.selected-network span {
  padding-top: 2px;
  line-height: 1;
}

.custom-select-header,
.custom-select-list {
  border: 1px solid var(--select-border-color, #b4b4b4);
  border-radius: 12px;
}

.custom-select ul {
  margin: 0;
  padding: 0;
  display: none;
  width: max-content;
}

.custom-select ul li {
  cursor: pointer;
}

.custom-select ul li:hover,
.custom-select ul li:focus {
  outline: none;
}
