#dashboard {
  > .container {
    padding: 0;

    > * {
      padding: 0 1rem;

      &.pills {
        padding: 1rem;
      }
    }

    > h1 {
      color: white;
      font-size: 2.75rem;
      font-weight: 600;
      text-wrap: balance;
      margin-top: 2.25rem;
      margin-bottom: 2rem;
      @media screen and (width > 768px) {
        font-size: 3.5rem;
      }
    }

    > p {
      text-align: center;
      color: #fff;
      font-size: 1.25rem;
      text-wrap: balance;
      margin-top: 15vh;
      @supports (margin-top: 15dvh) {
        margin-top: 15dvh;
      }
    }

    > ul {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      > li:last-child {
        margin-bottom: calc(100vh - 68px - 86px - 167px);

        @supports (margin-bottom: calc(100dvh - 68px - 86px - 167px)) {
          margin-bottom: calc(100dvh - 68px - 86px - 167px);
        }
      }
    }
  }
}
