@charset "utf-8";
@import 'node_modules/modern-normalize/modern-normalize.css';
@import './fonts.css';

:root {
  --background: #161617;
  --scroll-size: 6px;
  --base-font-size: 16px;
  --base-font-family: 'MadaniArabic', 'Arial', 'Helvetica', 'Ubuntu',
    'Open Sans', 'Segoe UI', 'Roboto', serif;
  --breakpoint-mobile: 480px;
  --breakpoint-tablet: 768px;
  --breakpoint-laptop: 1024px;
  --breakpoint-desktop: 1440px;
  --breakpoint-smartTv: 1920px;
}

/* html,
body {
  min-height: 100%;
  height: 100%;
} */

html {
  font-size: var(--base-font-size);
}

body {
  background-color: var(--background);
  font-family: var(--base-font-family);

  &::-webkit-scrollbar,
  ::-webkit-scrollbar {
    width: var(--scroll-size);
    height: var(--scroll-size);
    cursor: pointer;
  }
  &::-webkit-scrollbar-track,
  ::-webkit-scrollbar-track {
    background: #000;
  }
  &::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb {
    background: #122538;
    cursor: pointer;
  }
}

body:has(dialog[open]) {
  overflow: hidden;
}

button {
  border: none;
}

input {
  border: none;
  appearance: none;
}

ul,
menu {
  list-style: none;
  margin: 0;
}

a {
  appearance: none;
  text-decoration: none;
  color: currentColor;
}

button {
  cursor: pointer;
}

.wrapper {
  &.open {
    position: fixed;
    width: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    min-height: 100vh;

    @media (min-width: 768px) {
      max-width: calc(100vw - var(--scroll-size));
      display: block;
      @supports (height: 100dvh) {
        min-height: 100dvh;
      }
      @supports (width: 100dvw) {
        max-width: calc(100dvw - var(--scroll-size));
      }
    }
  }
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  @media screen and (width > 768px) {
    max-width: var(--breakpoint-laptop);
  }
}
