[class='kpi-role-metadatas-dialog'] {
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  width: auto;
  background-color: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 820px;
  margin: 0;
  padding: 2rem 3rem;

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(2px);
  }

  > form > header {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-bottom: 2rem;
    > button {
      background-color: #161617;
      backdrop-filter: blur(16px);
      color: white;
      font-weight: 600;
      padding: 0.5rem 1.25rem;
      border-radius: 33px;
    }

    > div {
      cursor: pointer;
      background-color: #7c81eb;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 37px;
      aspect-ratio: 1 / 1;

      .close {
        width: 16px;
        aspect-ratio: 1 / 1;
        border-radius: 100%;
        padding: 0.5rem;
        background-color: white;
        mask-size: contain;
        mask-repeat: no-repeat;
        display: inline-block;
        mask-image: url('/icons/cross.svg');
      }
    }
  }

  > form > h2 {
    font-weight: 500;
    text-wrap: pretty;
    text-align: center;
    margin-bottom: 3rem;
  }

  > form > section > ul {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    max-height: 600px;
    gap: 1rem;
    > li {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .switch {
        --toggle-width: 48px;
        --toggle-height: 18px;
        --toggle-slider-size: 28px;
        --toggle-slider-border-size: 3px;
        --toggle-slider-right-color: #9483ff;
        --toggle-slider-left-color: gray;
      }
    }

    &::-webkit-scrollbar-track,
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb,
    ::-webkit-scrollbar-thumb {
      background: #3e3e3e;
      cursor: pointer;
    }
  }

  > form > p {
    color: red;
    text-align: center;
  }
}
