.pill {
  --pill-width: 128px;
  position: absolute;
  color: white;
  font-size: 1rem;
  font-weight: 400;
  border: 1.5px solid white;
  border-radius: 4rem;
  background-color: transparent;
  padding: 1rem;
  flex-shrink: 0;
  text-transform: capitalize;
  transition: opacity 0.3s;
  width: var(--pill-width);
  left: 50%;
  /**
    64px width / 2
    16px padding-left parent
  */
  left: calc(50% - var(--pill-width) / 2 + 16px);

  &.pending {
    &::after {
      content: '';
      width: 1rem;
      height: 1rem;
      background-color: #ff7a2f;
      position: absolute;
      top: 0;
      right: 3px;
      border-radius: 100%;
    }
  }

  &.actual {
    background-color: #202238;
    border-color: #202238;
    font-weight: 400;
  }

  &.future {
    border: 1px solid #7a7a7a;
    background-color: var(--background);
  }

  &.active {
    color: #3f3f3f;
    font-weight: 600;
    background-color: white;
  }

  &:disabled:not(.active) {
    opacity: 0.4;
  }
}
