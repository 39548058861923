[id='global-error'] {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  gap: 2rem;

  > img {
    object-fit: contain;
    width: 220px;
  }

  > div {
    text-align: center;

    h1 {
      color: white;
      text-wrap: pretty;
      font-size: 2rem;
    }

    p {
      color: rgba(255, 255, 255, 0.8);
      text-wrap: balance;
    }
  }

  > p {
    color: rgba(255, 255, 255, 0.6);
  }

  a {
    background-color: white;
    padding: 1rem 1.5rem;
    border-radius: 24px;
    font-weight: 600;
  }
}
