.pills {
  --padding: 1rem;
  display: flex;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
  padding: 1rem 0;
  position: relative;
  position: sticky;
  top: calc(52px + 1rem);
  background-color: var(--background);
  z-index: 100;

  --gradient-width-desktop: 5rem;
  --gradient-width-mobile: 60px;

  > div.switch-freq {
    position: sticky;
    z-index: 101;
    left: 0;
    padding: 1px;
    background: rgb(22, 22, 23);
    background: linear-gradient(
      90deg,
      rgba(22, 22, 23, 1) 42%,
      rgba(12, 12, 13, 0.81) 74%,
      rgba(0, 0, 0, 0) 100%
    );
    width: var(--gradient-width-desktop);
    pointer-events: none;

    &:after {
      content: '';
      width: var(--padding);
      position: absolute;
      left: calc(-1 * var(--padding));
      top: 0;
      bottom: 0;
      background: var(--background);
    }

    .calendar {
      flex-shrink: 0;
      width: 3.25rem;
      aspect-ratio: 1/1;
      border-radius: 100%;
      pointer-events: all;

      img {
        max-width: 2rem;
      }
    }
  }

  &:not(.dragging) {
    > button {
      transition:
        opacity 0.3s,
        transform 0.5s;
    }
  }

  &::before {
    z-index: 100;
    position: absolute;
    top: var(--padding);
    bottom: var(--padding);
    right: var(--padding);
    width: var(--gradient-width-mobile);
    content: '';
    background: rgb(22, 22, 23);
    background: linear-gradient(
      270deg,
      rgba(22, 22, 23, 1) 18%,
      rgba(12, 12, 13, 0.81) 57%,
      rgba(0, 0, 0, 0) 100%
    );

    @media (min-width: 768px) {
      width: calc(var(--gradient-width-desktop) - 1.25rem);
    }
  }

  &::after {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: calc(100% - 1rem);
    background: var(--background);
    width: var(--padding);
  }
}
