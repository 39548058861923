#animated-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  filter: blur(125px);
  height: 100vh;
  @supports (height: 100dvh) {
    height: 100dvh
  }
  + .filter-noise {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    background-position: center;
    mix-blend-mode: color-burn;
    background-image: url('/images/noise.png');
    @supports (background-image: url('/images/noise.webp')) {
      background-image: url('/images/noise.webp')
    }
  }
  .out-top {
    animation: rotate 15s linear infinite;
    transform-origin: 25px 50px;
  }
  .in-top {
    animation: rotate 10s linear infinite;
    transform-origin: 25px 40px;
  }
  .out-bottom {
    animation: rotate 20s linear infinite;
    transform-origin: 75px 80px;
  }
  .in-bottom {
    animation: rotate 12s linear infinite;
    transform-origin: 75px 123px;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}