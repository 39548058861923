.switch {
  position: relative;
  display: inline-block;
  width: var(--toggle-width, 120px);
  height: var(--toggle-height, 55px);

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider:before {
      transform: translate(
        calc(
          (var(--toggle-width, 120px) / 2) -
            var(--toggle-slider-border-size, 10px)
        ),
        -50%
      );
      background-color: var(--toggle-slider-right-color, #ff5a64);
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    inset: 0;
    background: var(--toggle-background-color, rgba(71, 71, 71, 0.5));
    transition: 0.4s;
    border-radius: var(--toggle-radius, 50px);

    &::before {
      position: absolute;
      content: '';
      height: var(--toggle-slider-size, 75px);
      width: var(--toggle-slider-size, 75px);
      top: 50%;
      transform: translateY(-50%);
      background-color: var(--toggle-slider-left-color, #9483ff);
      border: var(--toggle-slider-border-size, 10px) solid
        var(--toggle-slider-border-color, #191818);
      transition: 0.4s;
      border-radius: var(--toggle-slider-radius, 50%);
    }
  }
}
