.guage-container {
  max-height: 224px;
  z-index: 100;
  flex: 1;
  border-radius: 29px 0px 0px 29px;
  border: 1px solid #1f2149;
  background: linear-gradient(180deg, #0f112e 0%, rgba(14, 17, 45, 0) 100%);
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
  transition: border-radius 0.3s;
  width: 320px;
  flex: 0 0 auto;

  &::before {
    content: url('/icons/hamburger.svg');
    right: -18px;
    top: 50%;
    position: absolute;
    width: auto;
    transform: translateY(-50%);
  }

  > h2 {
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: 1rem;
    margin: 0;
  }

  &.moved {
    border-radius: 0;
  }

  > img {
    position: absolute;
    left: 0.5rem;
    bottom: 1rem;
    max-width: 18px;
    aspect-ratio: 1/1;
  }

  @media (min-width: 768px) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    flex: 0 0 auto;

    &::before {
      display: none;
    }
  }
}
