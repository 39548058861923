[id='kpi-role-metadatas'],
[id='kpi-role-user'] {
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > h1 {
      flex: 1;
      color: white;
      font-size: 2.75rem;
      font-weight: 600;
      text-wrap: balance;
      margin-top: 2.25rem;
      margin-bottom: 2rem;
      @media screen and (width > 768px) {
        font-size: 3.5rem;
      }
    }
  }

  > table {
    margin: 4rem 0 8rem 0;
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;
    border-spacing: 1rem;

    th,
    td {
      border: 1px solid #4b4b4b;
      text-align: start;
      color: #fff;
      padding: 1rem;
    }

    thead {
      background-color: #0c1a27;
      th {
        font-weight: 500;
      }
    }

    .buttons {
      text-align: center;
    }

    .buttons {
      > img {
        cursor: pointer;

        &.disabled {
          opacity: 0.4;
          pointer-events: none;
        }
      }
    }
  }
}
