#main-header {
  padding-top: 1rem;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--background);
  z-index: 111;
  transition: padding-top 0.3s;

  &.isAtTop {
    padding-top: 1.75rem;
  }

  &.open {
    max-width: 100vw;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  img.logo {
    max-width: 148px;
    margin-top: 12px;
  }

  img.user-logo {
    max-width: 45px;
    aspect-ratio: 1 / 1;
    &.morocco {
      background-color: #ff4400;
      border-radius: 100%;
      padding: 0.5rem;
    }
  }

  .home {
    border-radius: 100%;
    background-color: white;
    border: none;
    width: 3.25rem;
    aspect-ratio: 1/1;

    > img {
      aspect-ratio: 1/1;
    }
  }

  .metrics-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .metrics-save {
    background-color: #4b57f5;
    border-radius: 4rem;
    padding: 1rem 1.5rem;
    color: white;
    font-weight: 600;
  }
  + .container {
    position: relative;
  }
}
