[class='barchart-container'] {
  padding: 1.5rem 1rem;
  background: #0f1230;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  padding-left: 1.5rem;
  max-height: 224px;
  max-width: calc(100vw - 20px);
  min-width: 0;
  flex: 1 1 auto;
  > div:first-child {
    display: flex;
    flex-direction: column;
    color: #fff;
    justify-content: space-between;
    margin-bottom: 24px;
    font-weight: 400;
    height: 100%;
  }

  > div.chart-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    margin-left: 8px;
    height: 100%;
    padding-bottom: 4px;

    &::-webkit-scrollbar {
      /* width: 90px; */
    }

    &::-webkit-scrollbar-track {
      background: #060714;
    }
    &::-webkit-scrollbar-thumb {
      background: #202877;
      cursor: pointer;
      border-radius: 17px;
    }
  }

  svg {
    overflow: visible;

    text {
      text-transform: capitalize;
    }
  }

  /* @media (min-width: 768px) {
    max-width: 600px;
  } */
}
