[id='kpi-admin-nav'] {
  margin-top: 1.5rem;
  display: flex;
  width: 100%;
  color: white;
  justify-content: space-around;
  align-items: center;
  max-width: 420px;
  font-weight: 400;
  font-size: 1.25rem;

  > a.active {
    color: #fcff61;
    font-weight: 600;
  }
}
