[id='filters-dialog'] {
  --selected-color: #fcff61;
  touch-action: pan-x;
  position: fixed;
  inset: 0;
  width: auto;
  height: 100%;
  z-index: 500;
  backdrop-filter: blur(10px);
  background: linear-gradient(0deg, #0b0b0b 50%, rgba(12, 12, 12, 0.6));
  border: none;

  /* TODO Check this */
  padding: calc(82px + 54px + 2rem + 1.75rem) 10px 7.25rem 10px;

  .container {
    height: 100%;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr) auto;
    grid-template-columns: minmax(0, 1fr);
    padding: 0 1.5rem;

    header {
      display: flex;
      gap: 0.5rem;
      justify-content: flex-end;
      align-items: center;
      height: 100%;

      .apply {
        border-radius: 33px;
        background: var(--selected-color);
        backdrop-filter: blur(8px);
        padding: 0.5rem 1rem;
        color: #42430e;
        font-size: 15px;
        font-weight: 600;
      }
      > div {
        background-color: #7c81eb;
        border: 1px solid #060b13;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 37px;
        aspect-ratio: 1 / 1;

        .close {
          width: 16px;
          aspect-ratio: 1 / 1;
          border-radius: 100%;
          padding: 0.5rem;
          background-color: white;
          mask-size: contain;
          mask-repeat: no-repeat;
          display: inline-block;
          mask-image: url('/icons/cross.svg');
        }
      }
    }

    > section {
      h2 {
        font-size: 2.75rem;
        color: white;
        font-weight: 600;
      }

      > input {
        border-radius: 50px;
        width: 100%;
        padding: 1rem;
        border: 1px solid #242323;
        background: #121212;
      }

      > div {
        display: flex;
        gap: 1rem;
        overflow-x: auto;

        &.categories {
          margin-top: 1.5rem;
          button {
            min-height: 55px;
            border-radius: 50px;
            color: white;
            background-color: var(--background);
            border: 1px solid #7a7a7a;
            padding: 1rem 0.75rem;
            font-weight: 600;

            &.selected {
              border: 1px solid var(--selected-color);
              background: #2d2e0e;
              color: var(--selected-color);
            }
          }
        }

        &.subcategories {
          margin-top: 2rem;
          overflow-y: hidden;
          button {
            background-color: transparent;
            color: #dbdbdb;
            text-wrap: nowrap;

            &.selected {
              font-weight: 600;
              color: var(--selected-color);
            }
          }
        }
      }
    }

    > footer {
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      gap: 1rem;

      > span {
        font-weight: 300;

        &.bold {
          font-weight: 600;
        }
      }
    }
  }
}
